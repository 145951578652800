import React, { useEffect, useState } from 'react'
import Sidebaar from '../../Component/Sidebar'
import { Col, Form } from 'react-bootstrap'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from 'axios';
import { BASE_URL } from '../../Api/BaseUrl';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { deletewithheader, getWithheader, getwithoutheader } from '../../Api/Api';
import moment from 'moment'
import TitleComp from '../../Component/TitleComp'
import { Box } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'

function Description() {
    let token = localStorage.getItem('paneltoken')
    const { state } = useLocation()

    const navigate = useNavigate()



    let userid = localStorage.getItem("userid")
    const [hiddenElements, setHiddenElements] = useState([]);

    const [desc, setdesc] = useState("")
    const [storename, setstorename] = useState("")
    const [storedata, setstoredata] = useState([])
    const [store, setstore] = useState("")


    const [file, setfile] = useState([])
    const [selectedImages, setSelectedImages] = useState([])
    const [shortdetail, setshortdetail] = useState("")
    const [editimage, seteditimage] = useState([])
    const [data, setdata] = useState([])
    const [loading, setloading] = useState(false)
    const [editid, seteditid] = useState("")



    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setdesc(data);
    };


    const handlestore = async () => {
        let res = await getWithheader(`vendorstore/${userid}`, token)
        setstoredata(res.data)
    }

    useEffect(() => {
        handlestore()
    }, [])


    const handlefetch = () => {
        setdesc(state.detail)
        setshortdetail(state.short_detail)
        setstorename(state.store_name)
        seteditimage(state.image)
        setstore(state.store._id)
    }

    useEffect(() => {
        if (state) {
            handlefetch()
        }
    }, [state])


    const handleImageChange = (e) => {
        const files = e.target.files;
        const imageArray = Array.from(files);
        setSelectedImages([...selectedImages, ...imageArray]);
    };

    const handleAllImagesRemove = (e) => {
        e.preventDefault();
        setSelectedImages([]);
        document.getElementById("image-input").value = "";
    };

    const handleSpecificRemove = (e, index) => {
        e.preventDefault();
        const newSelectedImages = selectedImages.filter((_, i) => i !== index);
        setSelectedImages(newSelectedImages);
    };

    const headers = {
        Authorization: `Bearer ${token}`
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // Create a FormData object
        let formData = new FormData();

        formData.append("store_name", storename);
        formData.append("short_detail", shortdetail);
        formData.append("detail", desc);
        formData.append("created_by", userid);
        formData.append("store", store);


        // Append images to formData
        selectedImages.forEach((item, index) => {
            formData.append(`image`, item);
        });
        // Make the request with axios
        if (editid) {
            axios.putForm(`${BASE_URL}description/${editid}`, formData, { headers })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.error == "1") {
                        toast.error(res.data.message)
                    } else {
                        toast.success(res.data.message)
                        setTimeout(() => {
                            navigate('/descriptionlist')
                        }, 500);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            axios.postForm(`${BASE_URL}description`, formData, { headers })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.error === 0) {
                        toast.error(res.data.message)
                        navigate('/product')
                    } else {
                        toast.success(res.data.message)
                    }
                })

                .catch((err) => {
                    console.error(err);
                });
        }


    };

    const clearAllimage = async (e) => {
        const res = await deletewithheader(`description/${state._id}/images`, token)
        console.log(res.error)
        if (res.error == "0") {
            toast.success(res.message)
            setTimeout(() => {
                navigate('/descriptionlist')
            }, 500);
        } else {
            toast.error("Image Not Deleted")
        }

    }

    const handleRemove = async (e, delid) => {
        e.preventDefault()
        const res = await deletewithheader(`image/${delid}`, token)
        if (res.error == "0") {
            toast.success(res.message)
            setHiddenElements([...hiddenElements, delid]);

            // setTimeout(() => {
            //     navigate('/descriptionlist')
            // }, 500);
        } else {
            toast.error("Image Not Deleted")
        }
    }
    const fetchdetail = async () => {
        let res = await getWithheader(`getuserdesc/${userid}`, token)
        setdata(res.data)
    }

    useEffect(() => {
        fetchdetail()
    }, [])
    const getRowId = (row) => row._id

    const columns = [
        // {
        //     field: "store_name",
        //     headerName: "Name",
        //     flex: 1,
        // },
        {
            field: "store",
            headerName: "Store",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <span>{params?.row?.store?.title}</span>
                    </>
                )
            }
        },
        {
            field: "short_detail",
            headerName: "Short Detail",
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "createdAt",
            flex: 1,
            renderCell: (params) => {
                return (moment(params.row.createdAt).format("DD MMMM YYYY") ?? "")
            }
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn actionbtn me-3' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
                        <button className='btn actionbtn' onClick={(e) => handledelete(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
                    </>
                )
            }
        },

    ]
    const handleedit = (e, item) => {
        e.preventDefault()
        setdesc(item.detail)
        seteditid(item._id)
        setshortdetail(item.short_detail)
        setstorename(item.store_name)
        seteditimage(item.image)
        setstore(item.store._id)
        // navigate('/description', { state: item })
    }



    const handledelete = async (e, deleid) => {
        e.preventDefault()
        setloading(true)


        let res = await deletewithheader(`description/${deleid}`, token)
        if (res.error == "0") {
            toast.success(res.message)
            fetchdetail()
            setloading(false)
        }


    }

    return (
        <>
            <Sidebaar content={
                <>
                    <div className="col-md-12">
                        <div className="text-center">
                            <h3>Description</h3>
                        </div>
                    </div>
                    <Form onSubmit={(e) => handleSubmit(e)}>
                        <div className="row gy-3">
                            {/* <div className="col-md-4">
                                <label htmlFor=""> Name</label>
                                <input type="text" value={storename} onChange={(e) => setstorename(e.target.value)} className='form-control' />
                           </div>  */}
                            <div className="col-md-4">
                                <label htmlFor="">Store</label>
                                <select name="" value={store} onChange={(e) => setstore(e.target.value)} className='form-control form-select' id="">
                                    <option value="">Select Store</option>
                                    {storedata.map((item) => {
                                        return (
                                            <>
                                                <option value={item._id}>{item.title}</option>
                                            </>
                                        )
                                    })}
                                </select>
                            </div>

                            <div className="col-md-4">
                                <label htmlFor="">Gallery Photo</label>
                                <input
                                    type="file"
                                    multiple
                                    // accept=".png, .jpg, .jpeg, .webp"
                                    onChange={handleImageChange}
                                    className="form-control"
                                    id="image-input"
                                />
                            </div>
                            <div className="col-md-4">
                                <label htmlFor="">Short Detail</label>
                                <input type="text" className='form-control' value={shortdetail} onChange={(e) => setshortdetail(e.target.value)} />
                            </div>
                            {/* <div className="col-md-4">
                                <div className=''>
                                    <label htmlFor="">Opening Hours</label>
                                    <select name="" className='form-control form-select' id="">
                                        <option value="">--Select--</option>
                                        <option value="">Sunday</option>
                                        <option value="">Monday</option>
                                        <option value="">Tuesday</option>
                                        <option value="">Wednesday</option>
                                        <option value="">Thursday</option>
                                        <option value="">Friday</option>
                                        <option value="">Saturday</option>
                                    </select>
                                </div>
                            </div> */}
                            <div className="col-md-12">
                                <label htmlFor="">Store Detail</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={desc}
                                    onChange={handleEditorChange}
                                    className="custom-ckeditor"
                                />
                            </div>

                            <div className="col-md-12">
                                <button className='btn bg-blue text-white'>Submit</button>
                            </div>
                        </div>
                    </Form>
                    <Col md="12">
                        <div className="">
                            {selectedImages.length > 0 && (
                                <button
                                    onClick={handleAllImagesRemove}
                                    className="remove-all-button mb-2 btn text-white bg-primary"
                                    style={{ fontSize: "12px" }}
                                >
                                    Remove All Images
                                </button>
                            )}
                            <div className="d-flex align-items-center gap-2 flex-wrap">
                                {selectedImages.map((image, index) => (
                                    <div key={index} className="image-preview position-relative">
                                        <button className='btn position-absolute bg-white' style={{ right: "2px", top: "2px" }} onClick={(e) => handleSpecificRemove(e, index)}><i className="fa-solid fa-xmark"></i></button>
                                        <img
                                            src={URL.createObjectURL(image)}
                                            className="img-fluid"
                                            style={{ height: "120px", borderRadius: "10px" }}
                                            alt={`Image ${index + 1}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>

                    {editimage.length > 0 && <div className="col-md-12">
                        <div className="d-flex align-items-center flex-wrap justify-content-between">
                            <h3>Images</h3>
                            <button className='btn bg-dark text-white' onClick={(e) => clearAllimage(e)}>Remove All Images</button>
                        </div>
                    </div>}
                    {editimage.map((item, index) => {
                        const isHidden = hiddenElements.includes(item._id);
                        return (
                            <>
                                <div key={index} className={`col-md-2${isHidden ? ' d-none' : ''}`}>
                                    <div className="position-relative">
                                        <button className='btn position-absolute bg-white' style={{ right: "2px", top: "2px" }} onClick={(e) => handleRemove(e, item._id)}><i className="fa-solid fa-xmark"></i></button>
                                        <img src={`${BASE_URL}${item.img}`} className='img-fluid' alt="" />
                                    </div>
                                </div >
                            </>

                        )
                    })}
                    <div className="col-md-12">
                        <div className="text-center">
                            <TitleComp title="Description Detail" />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <Box>
                            <DataGrid
                                rows={data}
                                columns={columns}
                                getRowId={getRowId}
                            />

                        </Box>
                    </div>

                </>
            } />
        </>
    )
}

export default Description
