import React, { useEffect, useState } from 'react'
import Sidebaar from '../../Component/Sidebar'
import TitleComp from '../../Component/TitleComp'
import { Form } from 'react-bootstrap'
import { deletewithid, getWithheader, getwithoutheader, postwithheader, putwithheader } from '../../Api/Api'
import Table from '../../Component/Table'
import Loader from '../../Component/Loader'
import { BASE_URL } from '../../Api/BaseUrl'
import axios from 'axios'
import { toast } from 'react-toastify'
import {
    CitySelect,
    StateSelect,

} from "react-country-state-city";
import { useNavigate } from 'react-router-dom'

function CreateStore() {
    let token = localStorage.getItem("paneltoken")
    let userid = localStorage.getItem("userid")
    const [catdata, setcatdata] = useState([])
    const [subcatdata, setsubcatdata] = useState([])
    const [storedata, setstoredata] = useState([])
    const [category, setcategory] = useState("")
    const [title, settitle] = useState("")
    const [subcategory, setsubcategory] = useState("")
    const [desc, setdesc] = useState("")
    const [loading, setloading] = useState(false)
    const [file, setfile] = useState(null)
    const [editid, seteditid] = useState("")
    const [statedata, setstatedata] = useState('');
    const [citydata, setcitydata] = useState("");
    const [stateid, setstateid] = useState(null);
    const [sector, setsector] = useState("")
    const [sectordata, setsectordata] = useState([]);
    const navigate = useNavigate()




    const handlesectorget = async () => {
        try {
            const res = await getwithoutheader('states');
            console.log(res.data);
            setsectordata(res.data);

        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };
    const handleget = async () => {
        let res = await getWithheader('category', token)
        console.log(res.data)

        setcatdata(res.data)
    }


    const handlesubcategory = async () => {
        let res = await getwithoutheader(`subcategory/${category}`)
        console.log(res)
        setsubcatdata(res.data)
    }

    useEffect(() => {
        if (category) {
            handlesubcategory()
        }
    }, [category])

    const handlestore = async () => {
        let res = await getWithheader(`vendorstore/${userid}`, token)
        console.log(res.data)

        setstoredata(res.data)
    }



    useEffect(() => {
        handleget()
        handlestore()
        handlesectorget()
    }, [])



    const columns = [
        {
            field: "image",
            headerName: "Image",
            flex: 1,
            renderCell: (params) => {
                return <img src={`${BASE_URL}${params.row.image}`} style={{ height: "40px", width: "40px", borderRadius: "50%" }} alt="" />
            }
        },
        {
            field: "category",
            headerName: "Category",
            flex: 1,
            renderCell: (params) => {
                return (
                    <><span>{params?.row.category?.name}</span></>
                )
            }
        },
        {
            field: "subcategory",
            headerName: "Sub Category",
            flex: 1,
            renderCell: (params) => {
                return (
                    <><span>{params?.row.subcategory?.type}</span></>
                )
            }
        },
        // {
        //     field: "_id",
        //     headerName: "id",
        //     flex: 1,
        // },
        {
            field: "title",
            headerName: "Title",
            flex: 1,
        },
        {
            field: "desc",
            headerName: "description",
            flex: 1,
        },
        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <button className='btn actionbtn me-3' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
                        <button className='btn actionbtn' ><i class="fa-solid fa-trash" onClick={(e) => handledelete(e, params.row._id)}></i></button>
                    </>
                )
            }
        },

    ]
    const headers = {
        Authorization: `Bearer ${token}`
    }

    const handledelete = async (e, deleid) => {
        e.preventDefault(); // Prevent page reload
        setloading(true);   // Set loading state while processing the request

        try {
            // Make the delete request to the backend
            const response = await axios.delete(`${BASE_URL}store/${deleid}`, { headers });




            if (response.data.error == 0) {
                toast.success("Store deleted successfully");
                handlestore()
            }

        } catch (error) {
            console.error("Error deleting store:", error);
        } finally {
            setloading(false); // Stop loading state after completion (success or error)
        }
    };


    const handleedit = (e, item) => {
        e.preventDefault()
        console.log(item)
        settitle(item.title)
        seteditid(item._id)
        setcategory(item?.category?._id)
        setdesc(item.desc)
        setsubcategory(item.subcategory._id)
        setsector(item.sector)

    }

    const getRowId = (row) => row._id


    const handlesubmit = async (e) => {

        e.preventDefault()


        let formdata = new FormData()

        formdata.append("title", title)
        formdata.append("desc", desc)
        formdata.append("created_by", userid)
        formdata.append("category", category)
        formdata.append("subcategory", subcategory)
        formdata.append("sector", sector)
        if (file) {
            formdata.append("image", file)
        }
        let res = ""
        if (editid) {
            res = await putwithheader(`store/${editid}`, token, formdata);
            if (res.error === 0) {
                toast.success("Data Updated Successfully");
                settitle("")
                
                setcategory("")
                setdesc("")
                setsubcategory("")
                setsector("")
                
                navigate('/description');
            }
        } else {
            res = await postwithheader('store', token, formdata);
            if (res.error === 0) {
                toast.success("Data Submitted Successfully");
                settitle("")
                
                setcategory("")
                setdesc("")
                setsubcategory("")
                setsector("")
                navigate('/description');
            }
        }


    }


    const handleimage = (e) => {
        let selected = e.target.files[0];
        setfile(selected); // This sets the file
        console.log(selected); // Log the selected file directly
    };




    return (
        <>
            {loading && <Loader />}
            <Sidebaar content={
                <>
                    <section>
                        <div className="container">
                            <Form onSubmit={handlesubmit}>
                                <div className="row gy-3">
                                    <div className="col-md-12">
                                        <div className="text-center">
                                            <TitleComp title="Create Store" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="">Store Image</label>
                                        <input type="file" onChange={handleimage} className='form-control' />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="">Select Sector</label>
                                        <select name="" value={sector} onChange={(e) => setsector(e.target.value)} className='form-control form-select' id="">
                                            <option value="">Select Sector</option>
                                            {sectordata?.map((state) => (
                                                <option key={state.sectorCode} value={state.sectorCode}>
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <label htmlFor="name">Select State</label>
                                        <StateSelect
                                            countryid={101}
                                            value={statedata}
                                            onChange={(e) => {
                                                setstatedata(e.name);    // Capture state name
                                                setstateid(e.id);        // Capture state ID
                                            }}
                                            placeHolder="Select State"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="name">Select City</label>
                                        <CitySelect
                                            countryid={101}
                                            stateid={stateid}
                                            value={citydata}
                                            onChange={(e) => setcitydata(e.name)}
                                            placeHolder="Select City"
                                        />
                                    </div> */}
                                    <div className="col-md-4">
                                        <label htmlFor="">Category</label>
                                        <select name="" value={category} onChange={(e) => setcategory(e.target.value)} className='form-control form-select' id="">
                                            <option value="">Select Category</option>
                                            {catdata?.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item._id}>{item.name}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="">Sub Category</label>
                                        <select name="" value={subcategory} onChange={(e) => setsubcategory(e.target.value)} className='form-control form-select' id="">
                                            <option value="">Select SubCategory</option>
                                            {subcatdata?.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item._id}>{item.type}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">Title</label>
                                            <input type="text" value={title} onChange={(e) => settitle(e.target.value)} className='form-control' />
                                        </div>

                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="">Description</label>
                                            <textarea name="" value={desc} onChange={(e) => setdesc(e.target.value)} id="" className='form-control'></textarea>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <button className='btn bg-primary text-white'>{editid ? "Update" : "Submit"}</button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </section>
                    <div className="col-md-12">
                        <Table data={storedata} columns={columns} getRowId={getRowId} />
                    </div>
                </>
            } />
        </>
    )
}

export default CreateStore
